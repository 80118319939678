export const SIGN_UP = 'SIGN_UP';
export const LOGIN = 'LOGIN';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_STAFFS = 'FETCH_STAFFS';
export const FETCH_SOCIALS = 'FETCH_SOCIALS';
export const FETCH_SOCIAL_DETAILS = 'FETCH_SOCIAL_DETAILS';
export const FETCH_USERS_DETAILS = 'FETCH_USERS_DETAILS';
export const FETCH_STAFF_DETAILS = 'FETCH_STAFF_DETAILS';
export const CLEAR = 'CLEAR';
export const ASSETS = 'ASSETS';
export const ASSET_DATA = 'ASSET_DATA';
export const FETCH_ASSETS_CONTAINERS = 'FETCH_ASSETS_CONTAINERS';
export const RECURRING_DATA = 'RECURRING_DATA';
export const ASSETS_COUNT = 'ASSETS_COUNT';
export const STAFFS_COUNT = 'STAFFS_COUNT';
export const USERS_COUNT = 'USERS_COUNT';
export const AVG_SCORE = 'AVG_SCORE';
export const FETCH_MATURITY = 'FETCH_MATURITY';
export const ORG_DETAILS = 'ORG_DETAILS';
export const ASSETS_ALL = 'ASSETS_ALL';
export const AVERAGE = 'AVERAGE';
export const OTHERS = 'OTHERS';
export const HARDWARE = 'HARDWARE';
export const SOFTWARE = 'SOFTWARE';
export const BUSINESS_CONTINUITY = 'BUSINESS_CONTINUITY';
export const CONNECTIVITY = 'CONNECTIVITY';
export const COUNT_ASSETS = 'COUNT_ASSETS';
export const CONTAINERS_ALL = 'CONTAINERS_ALL';
